(function ($) {

    // $.proofguide('/wp-content/themes/pp-v1.2-children/andrew-manning/resources/guide.jpg');

    $.addVideo("https://d3sw26zf198lpl.cloudfront.net/2018/03/26/5ab96432783ca.jpg", "https://d3sw26zf198lpl.cloudfront.net/2018/03/26/5ab9646f16a1c.webm", "https://d3sw26zf198lpl.cloudfront.net/2018/03/26/5ab9641b88cd4.mp4", true);

    // PARALLAX STARTER 
    $.getScript("https://d3sw26zf198lpl.cloudfront.net/2017/10/03/59d3e983405be.js", function () {
        var s = skrollr.init({
            render: function (data) {
                //Debugging - Log the current scroll position.
                //console.log(data.curTop);
            }
        });
        if (s.isMobile()) {
            s.destroy();
        }

    });

    $('#bcorr-section-1 .bcorr-agent').attr('data-0', 'transform: translateX(-300px); opacity: 0;');
    $('#bcorr-section-1 .bcorr-agent').attr('data--50-bottom', 'transform: translateX(0); opacity: 1;');
    $('#bcorr-section-1 .bcorr-agent').attr('data-anchor', '#bcorr-section-1');

    $('#bcorr-section-1 .bcorr-content').attr('data-0', 'transform: translateX(300px); opacity: 0;');
    $('#bcorr-section-1 .bcorr-content').attr('data--50-bottom', 'transform: translateX(0); opacity: 1;');
    $('#bcorr-section-1 .bcorr-content').attr('data-anchor', '#bcorr-section-1');

    $('#bcorr-section-2 .bcorr-content').attr('data-0', 'transform: translate(-500px, -50%); opacity: 0;');
    $('#bcorr-section-2 .bcorr-content').attr('data--50-bottom', 'transform: translate(0, -50%); opacity: 1;');
    $('#bcorr-section-2 .bcorr-content').attr('data-anchor', '#bcorr-section-2');

    $('#bcorr-section-4 .bcorr-content').attr('data-0', 'transform: translateX(700px); opacity: 0;');
    $('#bcorr-section-4 .bcorr-content').attr('data--50-bottom', 'transform: translateX(0); opacity: 1;');
    $('#bcorr-section-4 .bcorr-content').attr('data-anchor', '#bcorr-section-4');

    $('#bcorr-section-5 .bcorr-content').attr('data-0', 'transform: translateX(-700px); opacity: 0;');
    $('#bcorr-section-5 .bcorr-content').attr('data--50-bottom', 'transform: translateX(0); opacity: 1;');
    $('#bcorr-section-5 .bcorr-content').attr('data-anchor', '#bcorr-section-5');

    $('#bcorr-section-5 .bcorr-video').attr('data-0', 'transform: translateX(700px); opacity: 0;');
    $('#bcorr-section-5 .bcorr-video').attr('data--50-bottom', 'transform: translateX(0); opacity: 1;');
    $('#bcorr-section-5 .bcorr-video').attr('data-anchor', '#bcorr-section-5');

    $('#bcorr-section-6 .bcorr-content').attr('data-0', 'transform: translateX(-700px); opacity: 0;');
    $('#bcorr-section-6 .bcorr-content').attr('data--50-bottom', 'transform: translateX(0); opacity: 1;');
    $('#bcorr-section-6 .bcorr-content').attr('data-anchor', '#bcorr-section-6');

})(jQuery);

